import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { mq } from '../utils/helpers';
import CookieConsent from 'react-cookie-consent';

import {
  Footer,
  HeroImage,
  Layout,
  Boxes,
  HomeText,
} from '../containers';
import {Container, HeroContainer, StyledButton} from "../styled";

class HomePageTemplate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
        page: 3
    }
  }

  getData(key) {
    const { data } = this.props;
    return data.allMarkdownRemark.edges.filter(el => el.node.frontmatter[key])[0].node.frontmatter;
  }

  hClick() {

  }

  ShowMore() {
      if (this.state.page<7) {
          this.setState({page:this.state.page+1});
      }
  }

  render() {
    const { data } = this.props;
    const { boxes } = this.getData('boxes');
    const { contact } = this.getData('contact');
    const { poster } = this.getData('homePoster');

    return (
      <Layout renderWithChat>
        <HeroImage poster = {poster} changeAction={this.hClick.bind(this)} />

          <React.Fragment>
              <HeroContainer style={{marginTop:'0rem'}}>
                  <Container>
                      <ButtonsContainer>
                              <StyledButton>
                                  <Link to='/portfolio/filmy' style={{ textDecoration:'none', width:'100%', height:'100%', color: '#ffffff' }}>
                                  Видео
                                  </Link>
                              </StyledButton>

                              <StyledButton>
                                  <Link to='/portfolio/digital' style={{ textDecoration:'none', width:'100%', height:'100%', color: '#ffffff' }}>
                                  Digital
                                  </Link>
                              </StyledButton>

                              <StyledButton>
                                  <Link to='/portfolio/design' style={{ textDecoration:'none', width:'100%', height:'100%', color: '#ffffff' }}>
                                  Дизайн
                                  </Link>
                              </StyledButton>

                          <StyledButton>
                              <Link to='/kontakt' style={{ textDecoration:'none', width:'100%', height:'100%', color: '#ffffff' }}>
                              Контакты
                              </Link>
                          </StyledButton>
                      </ButtonsContainer>
                  </Container>
              </HeroContainer>
          </React.Fragment>

        <Boxes data={boxes} page={this.state.page} />

          {
              this.state.page <7?
              <HomeText data={data} marginTop={2}>
                  <div className="link-button" style={{fontSize: '30px', cursor:'pointer', textDecoration:'underline'}} onClick={() => this.ShowMore()}>Показать больше</div>
              </HomeText>:<div></div>
          }

        <HomeText data={data} marginTop={2} size='30px'>
         Вы великолепно выглядите! Вы уже знаете, что имидж очень важен, а&nbsp;это начало успеха. А мы? Мы маркетинговое агентство и работаем, чтобы довести Ваш успех до конца.
 
         Как? Мы занимаемся производством фильмов, делаем анимацию, разрабатываем графику и планируем стратегии цифрового маркетинга. Мы классные, и нам нравится работать с классными людьми, независимо от того, есть ли у них небольшой бизнес или известная и&nbsp;признанная компания. Вызов - это то, что нами движет, потому что мы знаем, что хорошо подобранная визуальная часть может хорошо «встряхнуть» рынок.
 
         У&nbsp;нас отличные специалисты и большой опыт. Мы предоставляем полный комплекс услуг, от концепции до продвижения. Мы гибкие, коммуникабельные и не боимся никакого бюджета. 
          
         С нетерпением ждем Вашего брифа!

        </HomeText>
        <HomeText data={data} marginTop={2}>
        </HomeText>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default HomePageTemplate;

const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0rem -1.5rem;
`;

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {  
            homePoster {
                publicURL
            }
            boxes {
              row {
                box {
                  title
                  destination
                  image_url {
                    childImageSharp {
                      fluid (maxWidth: 3080, quality: 100) { 
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  
                  hover_url {
                    publicURL
                  }
                  
                 }
              }
            }
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          }
        }
      }
    }
  }
`;
